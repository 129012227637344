<template>
  <item-text :title="`${customer.type.id === 1 ? 'Reģ. nr' : 'Pk'}`" :text="`${customer.reg_nr ? customer.reg_nr : 'Nav norādīts'}`" />
  <item-text title="PVN nr." :text="`${customer.vat_nr ? customer.vat_nr :  'Nav norādīts'}`" />
  <item-text title="Adrese" :text="customer.address" />
  <item-text title="Valsts" :text="`${customer.country ? customer.country.name : 'Nav norādīta'}`" />
  <item-text title="Telefona nr." :text="`${customer.phone ? customer.phone :  'Nav norādīts'}`" />
  <item-text title="E-pasts" :text="`${customer.email ? customer.email :  'Nav norādīts'}`" />
  <item-text title="Klienta tips" :text="customer.type.display_name" />
  <item-text title="Menedžeris" :text="`${customer.manager ? customer.manager.name : 'Nav norādīts'}`" />
  <item-text title="Eksportam" :text="`${customer.is_export ? 'Jā' : 'Nē'}`" />
  <item-text title="Aktīvs" :text="`${customer.is_active ? 'Jā' : 'Nē'}`" />
  <item-text v-if="customer.postpay_days" title="Pēcapmaksas dienas" :text="customer.postpay_days" />
    <template v-if="customer.invoice_emails">
        <item-text title="E-pasti rēķiniem" :text="customer.invoice_emails" />
    </template>

</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
  name: "ShowCustomerDetails",
  components: {
    ItemText
  },
  props: {
    customer: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style>

</style>